import { ref, watch } from '@vue/composition-api'
import { toastFail } from '@core/utils/prompt'
import store from '@/store'

export default function historyQueueList() {
  const headers = [
    { text: '平台', value: 'type', sortable: false },
    { text: '频道名称', value: 'channelName', sortable: false },
    { text: '子集名称', value: 'subsetName', sortable: false },
    { text: '播放次数', value: 'views', sortable: false },
    { text: '频道收益($)', value: 'estimatedRevenue', sortable: false },
    { text: 'CP分成', value: 'splitRatio', sortable: false },
    { text: '客户收益(应发$)', value: 'distributableIncome', sortable: false },
    { text: '手续费', value: 'taxDollar', sortable: false },
    { text: '客户收益(实发$)', value: 'incomeTotal', sortable: false },
    { text: '汇率', value: 'rate', sortable: false },
    { text: '客户收益(实发￥)', value: 'incomeRmb', sortable: false },
    { text: '账单时间', value: 'month', sortable: false },
    { text: '操作', value: 'action', sortable: false },
  ]
  const queueList = ref([])
  const totalLists = ref(0)
  const pageName = ref('')
  const member = ref('')
  const billTypeFilter = ref(null)
  const displayFilter = ref(null)
  const sendFilter = ref(null)
  const confirmFilter = ref(null)
  const date = ref()
  const loading = ref(false)
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
    itemsPerPage: 10,
    page: 1,
  })

  const fetchQueueList = () => {
    store.dispatch('history-list/fetchLists', {
      pageSize: options.value.itemsPerPage,
      page: options.value.page,
      sortBy: options.value.sortBy[0],
      sortDesc: options.value.sortDesc[0],

      // type: 'settled',
    })
      .then(response => {
        const { data, total } = response.data
        totalLists.value = total
        queueList.value = data
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        toastFail(error)
        console.log(error)
      })
  }

  const resolveShowTypeText = type => {
    if (type === 0) return '待设置'
    if (type === 1) return '显示'
    if (type === 2) return '不显示'

    return type
  }

  const resolveSendStatusText = type => {
    if (type === 0) return '未确认'
    if (type === 1) return '已确认'
    if (type === 2) return '发送失败'

    return type
  }

  watch([options], () => {
    loading.value = true
    fetchQueueList()
  })

  return {
    fetchQueueList,
    totalLists,
    queueList,
    options,
    headers,
    loading,
    date,
    member,
    pageName,
    billTypeFilter,
    displayFilter,
    sendFilter,
    confirmFilter,
    resolveShowTypeText,
    resolveSendStatusText,
  }
}
