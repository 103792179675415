import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLists(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/bill/myHistoryListWeb`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    template() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/unpaidBill/code`, {}, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sign(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/unpaidBill/updateIsPlay?key=${data}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    leadOut(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/unpaidBill/export?month=${data.month}&member=${data.member}&channelName=${data.channelName}`, {}, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItemDetails(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/admin/member/getEnterpriseAuditDetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
