<template>
  <div>
    <v-card
      v-show="!isRemoved"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          历史账单
        </slot>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-data-table
              :headers="headers"
              :items="queueList"
              :options.sync="options"
              :server-items-length="totalLists"
              :items-per-page="10"
              :loading="loading"
              no-data-text="暂无数据"
              no-results-text="未找到相关数据"
              loading-text="加载中，请稍等..."
            >

              <!-- splitRatio -->
              <template #[`item.splitRatio`]="{item}">
                <span>{{ item.splitRatio }}%</span>
              </template>

              <!-- distributableIncome -->
              <template #[`item.distributableIncome`]="{item}">
                <span>{{ item.distributableIncome&&item.splitRatio?(item.distributableIncome*(item.splitRatio/100)).toFixed(2):'0' }}</span>
              </template>

              <!-- action -->
              <template #[`item.action`]="{item}">
                <router-link
                  class="noUnderline"
                  :to="{name:'billSettlement-report',query:{id:item.id} }"
                >
                  查看报告
                </router-link>
              </template>
            </v-data-table>
          </template>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from './queueStoreModule'
import historyQueueList from './historyQueueList'

export default {
  components: {
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'history-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const generalData = ref({})
    const isPlanUpgradeDialogVisible = ref(false)
    const isDialogVisible = ref(false)
    const menu = ref(false)

    const {
      queueList,
      fetchQueueList,
      headers,
      options,
      loading,
      totalLists,
      date,
      member,
      pageName,
      billTypeFilter,
      displayFilter,
      sendFilter,
      confirmFilter,
      resolveShowTypeText,
      resolveSendStatusText,
    } = historyQueueList()

    const search = () => {
      loading.value = true
      options.value.page = 1
      fetchQueueList()
    }

    // 导入通过相关字符串获取数据的函数
    const open = ref([1, 2])
    const isRemoved = ref(false)

    return {
      queueList,
      totalLists,
      fetchQueueList,
      headers,
      open,
      isRemoved,
      options,
      loading,
      member,
      pageName,
      billTypeFilter,
      displayFilter,
      sendFilter,
      confirmFilter,
      resolveShowTypeText,
      resolveSendStatusText,
      date,
      menu,
      generalData,
      isPlanUpgradeDialogVisible,
      isDialogVisible,

      search,
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
.noUnderline{
  text-decoration: none;
}
</style>
